/*============================ loading google captcha ============================*/

let googleCaptchaScriptLoadingStatus = false;
window.addEventListener('scroll', function() {
		let scroll = window.scrollY;
		
		if (scroll > 0) {
				loadGoogleCaptchaScript();
				loadUserChatScript();
				loadCommonScripts()
		}
}, true);

window.addEventListener('touchstart', function() {
		loadGoogleCaptchaScript();
		loadUserChatScript();
		loadCommonScripts();
}, {passive: true});

$(function () {

		// ====================== On login Modal Shown 
		$('#loginModal').on('show.bs.modal', () => { 
				loadGoogleCaptchaScript();
				loadUserChatScript();
				loadCommonScripts()
		});

		// ======================On assistant Modal Shown 
		$('#AssistantModal').on('show.bs.modal', () => { 
				loadGoogleCaptchaScript();
				loadUserChatScript();
				loadCommonScripts()
		});

		// ======================wishlist modal shown
		$('#ShortlistModal').on('show.bs.modal', () => {
				loadGoogleCaptchaScript();
				loadUserChatScript();
				loadCommonScripts()
		});

	// ======================requestCallBack
	$('#requestCallBack').on('show.bs.modal', () => {
		loadGoogleCaptchaScript();
		loadUserChatScript();
		loadCommonScripts()
	});
		
		//======================= On contact or registration page
		if(pageType !== 'undefined'){
				if(['CONTACT', 'REGISTRATION', 'THANK_YOU', 'PAID_COUNSELLING'].includes(pageType)) {
						loadGoogleCaptchaScript();
						loadUserChatScript();
						loadCommonScripts()
				}
		}

	// window.addEventListener("scroll", handleScroll);

	// document.addEventListener("touchstart", handleTouchStart);

	// document.addEventListener("mousemove", handleMouseMove);
		
		

});

/*============================ /loading google captcha ============================*/

async function loadGoogleCaptchaScript(){
		let scriptLoaded = '';
		if(googleCaptchaScriptLoadingStatus === false){
				googleCaptchaScriptLoadingStatus = true;
				
				scriptLoaded = await lazyLoadThisScript('body', [
						{
								"src": "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit",
								"async": 1,
								"defer": 1
						},
				], 'last', 100);		
		}
}

let userChatScriptLoadingStatus = false;
async function loadUserChatScript(){
		let scriptLoaded = '';
		if(userChatScriptLoadingStatus === false){
				userChatScriptLoadingStatus = true;

				scriptLoaded = await lazyLoadThisScript('body', [
						{"src": `${window.location.origin}/assets/js/user_chat1.js`},
						{"src": `${window.location.origin}/common/js/badWords.js`},
						/*{"src": `${window.location.origin}/common/js/intlTelInput-jquery.min.js`}*/
				], 'last', 100);
		}
}

let commonScriptsLoadingStatus = false;
async function loadCommonScripts(){
		let styleLoaded = '';
		if(commonScriptsLoadingStatus === false){
				commonScriptsLoadingStatus = true;

				styleLoaded = await lazyLoadCss([
						`${window.location.origin}/assets/build/Assistant.css`,
						`${window.location.origin}/common/css/intlTelInput.min.css`,
						// `${window.location.origin}/assets/fontawesome/css/font-awesome.min.css`,
						
				], 'last', 100);
				
		}
}

function handleTouchStart() {
	loadScriptsChunk()
	document.removeEventListener("touchstart", handleTouchStart);
}

function handleScroll() {
	if (window.scrollY > 0) {
		loadScriptsChunk()
		window.removeEventListener("scroll", handleScroll);
	}
}

function handleMouseMove(event) {
	loadScriptsChunk();
	document.removeEventListener("mousemove", handleMouseMove);
}

let loadingScriptStatus = false;
async function loadScriptsChunk() {
	if (loadingScriptStatus) return;

	loadingScriptStatus = true;
	
	await lazyLoadCss([
		`${window.location.origin}/home-page/assets/css/slick-theme.css`,
		`${window.location.origin}/home-page/assets/css/slick.css`,
	], 'last', 100);

}
